import {faPalette, faReceipt, faPencilRuler} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import SAMLIcon from '@material-ui/icons/DeviceHub';
import HelpIcon from '@material-ui/icons/HelpOutline';
import RadiusIcon from '@material-ui/icons/SettingsEthernet';
import React, {useEffect, useState} from 'react';
import {MenuItemLink, useDataProvider, usePermissions, useRedirect, useTranslate} from 'react-admin';
import {connect, useDispatch} from 'react-redux';
import {HELP_BASE_PATH} from "../../constants";
import HttpsIcon from '../../Icons/HttpsConnector24Px';
import AccountsIcon from '../../Icons/MenuAccounts';
import ConnectorsIcon from '../../Icons/MenuConnectors';
import DirectoriesIcon from '../../Icons/MenuDirectories';
import ServicesIcon from '../../Icons/MenuServices';
import UsersIcon from '../../Icons/MenuUsers';
import OpenidIcon from '../../Icons/Openid24Px';
import TokenPackIcon from '../../Icons/TokenPack';
import {GET_SUPPORT_SERVICE_FEATURES} from '../../subscription/data-provider';
import log from "../../utils/log";
import SecurityChecker from '../../utils/security';
import LocalStorage, {SCOPE_CUSTOMER, SCOPE_VKEY} from '../../utils/services/storage';
import {
    updateDashboardView as updateDashboardViewAction,
    updateScope as updateScopeAction,
    updateSupportServiceFeatures as updateSupportServiceFeaturesAction
} from '../menuReducers';
import SubMenu from '../SubMenu';

const styles = makeStyles((theme) => ({
    // active: {
    //     backgroundColor: '#533475',
    // },
    root: {
        color: 'white',
        // background: 'rgb(83, 52, 117)'
        padding: '12px 16px'
    },
    contextContent: {
        position: 'fixed',
        bottom: 15,
        color: 'white',
        fontSize: '1.2rem',
        // padding: 16,
        maxWidth: 250,
        textAlign: 'center',
        width: '100%'
    }
}))

const menuStyles = makeStyles((theme) => ({
    // '& active': {
    //     backgroundColor: '#533475',
    // },
    root: {
        color: 'white',
        // background: 'rgb(83, 52, 117)'
        padding: '12px 16px'
    },
    // listItem: {
    //     height: "48px"
    // }
}))

const CustomerIdmMenu = (props) => {
    // props.updateDashboardView(MY_ACC_DASHBOARD);
    const theme = useTheme();
    const menuClasses = menuStyles(theme)
    const [menu, setMenu] = useState({
        menuCustomers: false,
        menuUsers: false,
        menuConnectors: false
    })
    const [customerId, setCustomerId] = useState([null])
    const [supportServiceFeatures, setSupportServiceFeatures] = useState([])
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const {loaded, permissions} = usePermissions();
    const redirectTo = useRedirect()
    const dispatch = useDispatch();
    const handleToggle = name => {
        setMenu({
            ...menu,
            [name]: !menu[name]
        })
    };


    const getServiceFeatureSupport = (customerId) => {
        if (customerId) {
            dataProvider(GET_SUPPORT_SERVICE_FEATURES, 'billing', {},
                {
                    onSuccess: {},
                    onFailure: {
                        notification: {body: 'Error: Get Support Service Features Failed', level: 'warning'}
                    }
                }).then(rsp => {
                setSupportServiceFeatures(rsp.data);
            }).catch(e => {
                log.error(e)
            });
        }
    }

    useEffect(() => {

        // Load saml config for this customer
        // var { data } = await dataProvider(GET_ONE, 'saml', {});
        // this.setState({ samlId: data.id });

        setCustomerId(LocalStorage.instance.getCustomerId())

        // Load localstorage and set scope
        let scope = LocalStorage.instance.getCurrentScope()
        props.updateScope(scope)

        //// log.debug('----- UNSAFE_componentWillMount with customer idm menu');

        // get service feature support
        //// log.debug('----> Getting support service features');
        getServiceFeatureSupport(customerId);
    }, [customerId])

    // // log.debug("Menu props: ", this.props);
    //let scope = this.props.scope || LocalStorage.instance.getCurrentScope()

    const {
        originalScope, currentScope,
        onMenuClick, open, logout, updateScope,
        history
    } = props;

    let scope = currentScope;

    // const translate = useTranslate()
    const classes = styles(theme)

    // let originalScope = LocalStorage.instance.getOriginalScope()
    // let currentScope = this.props.scope || LocalStorage.instance.getCurrentScope()
    let customerName = LocalStorage.instance.getCustomerName()

    // update menu with support service features

    return loaded ? (
        <div id="left-menu">
            {' '}
            {/* <DashboardMenuItem onClick={onMenuClick} style={styles.default} activeStyle={styles.active} /> */}

            {customerId &&
            <MenuItemLink
                to={`/report`}
                primaryText={translate(`resources.report.name`, {
                    smart_count: 2,
                })}
                leftIcon={<FontAwesomeIcon icon={faReceipt} style={{width: 24, height: 24}}/>}
                onClick={onMenuClick}
                classes={menuClasses}
            />
            }
            {customerId &&
                <MenuItemLink
                    to={`/audit`}
                    primaryText={translate(`resources.audit_log.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<FontAwesomeIcon icon={faPencilRuler} style={{width: 24, height: 24}}/>}
                    onClick={onMenuClick}
                    classes={menuClasses}
                />
            }
            {customerId &&
            <MenuItemLink
                to={`/account`}
                primaryText={translate(`resources.accounts.name`, {
                    smart_count: 2,
                })}
                leftIcon={<AccountsIcon/>}
                onClick={onMenuClick}
                // activeStyle={styles.active}
                // style={styles.default}
                classes={menuClasses}
            />
            }

            {SecurityChecker(permissions, 'directories', ['view', 'modify', 'create', 'delete'], 'OR') &&
            scope == SCOPE_CUSTOMER &&
            <MenuItemLink
                to={`/ldap`}
                primaryText={translate(`resources.ldap.name`, {
                    smart_count: 2,
                })}
                leftIcon={<DirectoriesIcon/>}
                onClick={onMenuClick}
                classes={menuClasses}
            />
            }

            {scope == SCOPE_CUSTOMER &&
            <SubMenu
                handleToggle={() => handleToggle('menuConnectors')}
                isOpen={menu['menuConnectors']}
                sidebarIsOpen={open}
                name="pos.menu.connectors"
                icon={<ConnectorsIcon/>}
                classes={{ ...menuClasses, listItem: classes.listItem }}
            >
                <MenuItemLink
                    to={`/radius`}
                    primaryText={translate(`resources.radius.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<RadiusIcon/>}
                    onClick={onMenuClick}
                    classes={menuClasses}
                />

                <MenuItemLink
                    to={`/saml2`}
                    primaryText={translate(`resources.saml2.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<SAMLIcon/>}
                    onClick={onMenuClick}
                    classes={menuClasses}
                />
                <MenuItemLink
                    to={`/oidc`}
                    primaryText={translate(`resources.oidc.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<OpenidIcon/>}
                    onClick={onMenuClick}
                    classes={menuClasses}
                />
                 <MenuItemLink
                    to={`/https`}
                    primaryText={translate(`resources.https.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<HttpsIcon/>}
                    onClick={onMenuClick}
                    classes={menuClasses}
                />
                <MenuItemLink
                    to={`/ldap_2fa`}
                    primaryText={translate(`resources.ldap2fa.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<DirectoriesIcon/>}
                    onClick={onMenuClick}
                    classes={menuClasses}
                />

            </SubMenu>}

            {
                SecurityChecker(permissions, 'service_instance', ['view', 'configure'], 'OR')
                        &&
                        <MenuItemLink
                            to={`/service-launch`}
                            primaryText={translate(`resources.serviceLaunchs.name`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ServicesIcon />}
                            onClick={onMenuClick}
                            classes={menuClasses}
                        />
            }

            {
                SecurityChecker(permissions, 'token-pack', ['view', 'modify', 'create', 'delete'], 'OR')
                    &&
                    <MenuItemLink
                        to={`/token-pack-v2`}
                        primaryText={translate(`resources.tokenpacks.name`, {
                            smart_count: 2,
                        })}
                        leftIcon={<TokenPackIcon style={{ width: 24, height: 24 }} />}
                        onClick={onMenuClick}
                        classes={menuClasses}
                    />
            }

            {supportServiceFeatures && supportServiceFeatures.theme &&
                SecurityChecker(permissions, 'theme', ['view', 'configure'], 'OR')
                    &&
                    <MenuItemLink
                        to={`/theme`}
                        primaryText={translate(`resources.themes.name`, {
                            smart_count: 2,
                        })}
                        leftIcon={<FontAwesomeIcon icon={faPalette} style={{ fontSize: 24 }} />}
                        onClick={onMenuClick}
                        classes={menuClasses}
                    />
            }

            {/*<WithPermissions*/}
            {/*    {...props}*/}
            {/*    render=*/}
            {/*    {({ permissions }) => (*/}

            { SecurityChecker(permissions, 'user-management',  ['view', 'modify', 'create', 'delete'], 'OR')
                ?
                <MenuItemLink
                    to={`/users`}
                    primaryText={translate(`resources.users.name`, {smart_count: 2})}
                    leftIcon={<UsersIcon/>}
                    onClick={onMenuClick}
                    classes={menuClasses}
                />
                : null
            }
             {/*   )}*/}
             {/*/>*/}
            <MenuItemLink
                to={false}
                primaryText={"Help"}
                leftIcon={<HelpIcon/>}
                onClick={e => {
                    // open help tab
                    window.open(HELP_BASE_PATH, '_blank')
                }}
                classes={menuClasses}
            />

            {(originalScope == SCOPE_VKEY && currentScope == SCOPE_CUSTOMER) &&
                <div className={classes.contextContent}>
                    <div>{customerName}</div>
                    <Button variant="contained" color="primary" style={{ width: '80%', color: 'black', marginTop: 15, backgroundColor: 'white' }}
                        onClick={e => {
                            // log.debug('Return to vkey page')
                            LocalStorage.instance.setCustomerId(null)
                            LocalStorage.instance.setCustomerName(null)
                            LocalStorage.instance.setCurrentScope(SCOPE_VKEY)
                            LocalStorage.instance.setCustomerAccessToken(null)

                            dispatch(updateScope(SCOPE_VKEY))
                            redirectTo('/customer')
                            // window.location.reload()
                        }}>Return</Button>
            </div>
            }

        </div>
    ) : null;
}

const mapStateToProps = state => ({
    open: state.admin.ui.sidebarOpen,
    theme: state.theme,
    serviceLaunchData: state.serviceLaunchData,
    scope: state.menu.scope,
    supportServiceFeatures: state.menu.supportServiceFeatures,
    close: state.admin.ui.sidebarClose
});

const mapDispatchToProps = dispatch => ({
    updateScope: data => updateScopeAction(data),
    updateSupportServiceFeatures: data => updateSupportServiceFeaturesAction(data),
    updateDashboardView: data => updateDashboardViewAction(data),
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerIdmMenu);
