import constants from './constants';
import LocalStorage, {SCOPE_CUSTOMER, SCOPE_VKEY} from './utils/services/storage'
import httpClient from './dataProvider/httpClient';
import log from './utils/log';
import {stringify} from "querystring";

function sleep(time) {
    // log.debug('[Login] force redirect to url waiting...');
    return new Promise((resolve) => {
        // log.debug('[Login] force redirect to url in promise');
        setTimeout(resolve, time || 1000);
    });
}

function getPermissionWithCache(cacheDisabled = false) {
    const token = LocalStorage.instance.getToken();
    //// log.debug(token);
    console.log("calling getPermission", cacheDisabled);
    if (token == undefined || token == null) {
        return Promise.resolve([]);
    } else {
        const request = new Request(constants.API_URL + '/account/permission?flattern=1', {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + LocalStorage.instance.getToken()
            }),
            ...(cacheDisabled && {cache:"reload"})
        })

        // check cache
        let permissionCache = LocalStorage.instance.getAccountPermission();
        //// log.debug('permissionCache', permissionCache);
        if (permissionCache && permissionCache.expire == false) {
            // // log.debug('we got the data from cache');
            // // log.debug(permissionCache);
            return Promise.resolve(permissionCache.data);
        } else if (permissionCache && permissionCache.data && permissionCache.expire) {
            // expire - force to get new data

            (async () => {
                //// log.debug('waiting fetch');
                let response = await fetch(request);
                // // log.debug('waiting response');
                let permissions = await response.json();
                //// log.debug('finished get permission');
                //// log.debug(permissions);

                // put to cache
                LocalStorage.instance.setAccountPermission(permissions, constants.ACCOUNT_PERMISSION_EXP_IN_SEC);

            })();

            return Promise.resolve(permissionCache.data);
        } else {
            // call to server to get new data
            // this wrapper function to make wait for server's response in order to have permission datas
            return (async () => {
                //// log.debug('waiting fetch');
                let response = await fetch(request);
                // // log.debug('waiting response');
                let permissions = await response.json();
                //// log.debug('finished get permission');
                //// log.debug(permissions);
                // put to cache
                LocalStorage.instance.setAccountPermission(permissions, constants.ACCOUNT_PERMISSION_EXP_IN_SEC);

                return Promise.resolve(permissions);
            })();
        }
    }
}

export default {

    login: ({ username, password, state }) => {
        const authReq = {
            login: username,
            password: password,
            state: state
        };

        return httpClient(constants.API_URL + '/auth/signin', {
            method: 'POST',
            body: JSON.stringify(authReq),
        })

            .then(response => {
                // log.debug('Auth code moi:::', response)
                let body = JSON.parse(response.body)
                return body
            })
            .then((json) => {
                log.info("Auth response json::: ", json)
                let { redirectUrl, token, account, tokenExpireInSec } = json
                // if we have redirectUrl - then force to redirect to that URL
                if (redirectUrl) {
                    // log.debug('[Login] force redirect to url', redirectUrl);
                    setTimeout(function() {
                        // change location
                        // log.debug('[Login] Ready to redirect to url', redirectUrl);
                        window.location.href = redirectUrl;
                    }, 5);

                    // make the thread wait
                    return sleep(10000);

                } else {
                    LocalStorage.instance.removeToken();
                    LocalStorage.instance.setUser(null)
                    LocalStorage.instance.setCustomerId(null);
                    LocalStorage.instance.setCurrentScope(null)
                    LocalStorage.instance.setOriginalScope(null)


                    LocalStorage.instance.setToken(token, tokenExpireInSec)
                    LocalStorage.instance.setUser(account)
                    LocalStorage.instance.setCustomerId(account.customerId);

                    let currentScope = account.customerId ? SCOPE_CUSTOMER : SCOPE_VKEY

                    // log.debug('Logged in SCOPE: ', currentScope)
                    LocalStorage.instance.setCurrentScope(currentScope)
                    LocalStorage.instance.setOriginalScope(currentScope)

                    if (account.forceChangePassword) {
                        //LocalStorage.instance.setLoginRedirectPath('/my-account/change-password');
                        //window.globalHistory.push('/my-account/change-password');
                        // setTimeout here - maybe localsStorage.setItem still not finished written to disk
                        setTimeout(function() {
                            // change location
                            window.location.href = '/my-account/change-password';
                        }, 100);

                    }

                }

                setTimeout(async function (){
                    const customerId = LocalStorage.instance.getCustomerId();
                    const query = {
                        customerId,
                        page: 0,
                        pageSize: 1,
                        status: 'ACTIVE'
                    }
                    let { json } = await httpClient(`${constants.API_URL}/user-management/users/${customerId}/search?${stringify(query)}`, {method: 'GET'})
                    console.info("Check-is-fresh-login :: ", json)
                    if (json.data.content.length === 1)
                        window.location.href = "/report"
                    else
                        window.location.href = "/token-pack-v2"
                }, 100)

            })
            .then( () => getPermissionWithCache(true))
            .catch(e => {
                log.info(e.body)
                if(e.body.status === 500 && e.body.error) {
                    return Promise.reject(e.body.error)
                }
                else {
                    return Promise.reject(e.body.message)
                }
                // throw new SubmissionError({ _error: "Your email or password is incorrect", form: 'signIn' })
            })
    },

    logout: () => {
        LocalStorage.instance.logout();

        // log.debug('on logout - token', LocalStorage.instance.getToken());

        // clear permission cache
        LocalStorage.instance.clearAccountPermission();

        return Promise.resolve();
    },

    checkError: error => {
        return Promise.resolve();
    },
    checkAuth: () => {
        // // log.debug(params);
        return LocalStorage.instance.getToken()
            ? Promise.resolve()
            : Promise.reject();
    },
    getPermissions: () => {
        // // log.debug('get permission');
        //// log.debug(params);
        return getPermissionWithCache();
    }

};
